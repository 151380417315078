import FormButton from "@legacyComponents/FormButton/FormButton.component";
import LockIcon from "@assets/icons/lock.svg";
import Tooltip from "@components/Tooltip";
import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, StyledBoldText } from "../summary/styles/summary.style";
import { OptionsContainer } from "../summary/styles/topup.style";
import { HeaderTextContainer, StyledForm } from "./giftaid.style";
import {
  zakatSelector,
  zakatTopUpSelector,
} from "@Zakat/store/zakatV2/zakat.selector";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import { ZakatGiftAidSchemaV2 } from "./data";
import { Formik } from "formik";
import { ButtonContainer } from "./giftaid.style";
import { CurrencyContext } from "@components/CurrencyContext";
import useAnalytics from "@helpers/useAnalytics";
import PageWrapper from "@Zakat/components/PageLayout/PageWrapper";
import { charitySelector } from "@Zakat/store/charity/charity.selector";
import { investorSelector } from "@Zakat/store/investor/investor.selector";
import { updateZakatAsync } from "@Zakat/store/zakatV2/zakat.action";
import { updateInvestor } from "@helpers/clientInvestSdk";
import { ZakatGiftAidV2 } from "./types";

const ZakatGiftAidPage: React.FC = () => {
  const dispatch = useDispatch();
  const zakat = useSelector(zakatSelector);
  const topUp = useSelector(zakatTopUpSelector) || 0;
  const charity = useSelector(charitySelector);
  const investor = useSelector(investorSelector);

  const isKYCApproved = investor?.kycProfile?.status === "APPROVED";
  const showForm = !isKYCApproved;

  const { formatCurrency } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView("zakat:giftAid_pageViewed");
  }, []);

  // zakat amount + top up x %charity allocation x 25% = giftAid amount
  const getGiftAidPrice = () => {
    if (!zakat) return 0;
    const zakatAmountDue = zakat?.amountDue || 0;
    const totalPayableAmount = zakatAmountDue + topUp;
    return totalPayableAmount * (charity.allocation / 100) * 0.25;
  };

  const giftAidData: ZakatGiftAidV2 = {
    firstName: investor?.firstName || "",
    lastName: investor?.lastName || "",
    addressLine1: investor?.address?.addressLine1 || "",
    addressLine2: investor?.address?.addressLine2 || "",
    city: investor?.address?.city || "",
    postcode: investor?.address?.postcode || "",
    buildingNameOrNumber: investor?.address?.buildingNameOrNumber || "",
    country: investor?.address?.country || "GB", // gift aid is only available in the UK
  };

  const handleBack = () => {
    navigate("/app/top-up");
  };

  const handleClaimGiftAid = async (values?: ZakatGiftAidV2) => {
    if (!zakat?.id) return;
    if (showForm && values) {
      const { firstName, lastName, ...address } = values;
      await updateInvestor({
        firstName,
        lastName,
        address,
      });
    }

    await dispatch(
      updateZakatAsync({
        enableGiftAid: true,
      })
    );

    navigate("/app/payment");
  };

  const handleNoGiftAid = async () => {
    if (!zakat?.id) return;
    await dispatch(
      updateZakatAsync({
        enableGiftAid: false,
      })
    );

    navigate("/app/payment");
  };

  return (
    <PageWrapper>
      <HeaderTextContainer>
        <Header>Gift Aid</Header>
        <p>
          Give more without costing you a penny. Add Gift Aid and the UK
          government will top up your donation by 25%.
        </p>
        <p>
          By choosing Gift Aid, you confirm that you are a UK taxpayer and that
          you would like to reclaim 25p of tax for every £1 of your donation
          made in the last four years and any future donations at no extra cost
          for you.
        </p>
        <Tooltip
          label="Important Information"
          text="Gift Aid is a tax relief allowing UK charities to reclaim an extra 25% in tax on every eligible donation made by a UK taxpayer. If you pay less income Tax and/or Capital Gains Tax in the current year than the amount of Gift Aid claimed on your donations in that tax year, it is your responsibility to pay the difference. We can’t claim Gift Aid on a donation made by you on behalf of a company or a friend. Higher rate taxpayers can claim tax relief on the donation. Gift Aid is not part of your Zakat."
        />
      </HeaderTextContainer>

      <OptionsContainer>
        <div>
          <StyledBoldText>
            Yes, claim {formatCurrency(getGiftAidPrice())} of Gift Aid
          </StyledBoldText>
        </div>
        <div>
          <button onClick={handleNoGiftAid}>
            <StyledBoldText>No, do not claim Gift Aid</StyledBoldText>
          </button>
        </div>
      </OptionsContainer>
      {showForm && (
        <Formik
          validationSchema={ZakatGiftAidSchemaV2}
          initialValues={giftAidData}
          onSubmit={handleClaimGiftAid}
        >
          {({ values, setFieldValue, errors, touched }) => {
            const handleChange = (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setFieldValue(e.target.name, e.target.value);
            };

            return (
              <StyledForm>
                <p>
                  Please confirm a few details so we can claim your gift aid. We
                  will only use your information for this purpose.
                </p>
                <Input
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                  heading="First Name(s)"
                  placeholder="Legal first name"
                  errorMessage={
                    touched.firstName && errors.firstName
                      ? errors.firstName
                      : ""
                  }
                />
                <Input
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  heading="Last Name"
                  placeholder="Legal last name"
                  errorMessage={
                    touched.lastName && errors.lastName ? errors.lastName : ""
                  }
                />
                <Input
                  value={values.buildingNameOrNumber}
                  name="buildingNameOrNumber"
                  onChange={handleChange}
                  heading="Building Name or Number"
                  placeholder="Enter here"
                  errorMessage={
                    touched.buildingNameOrNumber && errors.buildingNameOrNumber
                      ? errors.buildingNameOrNumber
                      : ""
                  }
                />
                <Input
                  value={values.addressLine1}
                  name="addressLine1"
                  onChange={handleChange}
                  heading="Address Line 1"
                  placeholder="Enter here"
                  errorMessage={
                    touched.addressLine1 && errors.addressLine1
                      ? errors.addressLine1
                      : ""
                  }
                />
                <Input
                  value={values.addressLine2 || ""}
                  name="addressLine2"
                  onChange={handleChange}
                  heading="Address Line 2 (optional)"
                  placeholder="Enter here"
                />
                <Input
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  heading="City"
                  placeholder="Enter your city"
                  errorMessage={touched.city && errors.city ? errors.city : ""}
                />
                <Input
                  value={values.postcode}
                  name="postcode"
                  onChange={handleChange}
                  heading="Post Code"
                  placeholder="Enter post code"
                  errorMessage={
                    touched.postcode && errors.postcode ? errors.postcode : ""
                  }
                />
                <ButtonContainer>
                  <FormButton
                    type="submit"
                    text="Next"
                    leftArrowLabel="Back"
                    disclaimerIcon={<LockIcon />}
                    disclaimerText="We take your privacy seriously. Our institutional grade security ensures ensures your data is confidential."
                    leftArrowOnClick={() => navigate("/app/top-up")}
                  />
                </ButtonContainer>
              </StyledForm>
            );
          }}
        </Formik>
      )}
      {!showForm && (
        <ButtonContainer>
          <FormButton
            type="button"
            text="Next"
            leftArrowLabel="Back"
            onClick={() => handleClaimGiftAid()}
            disclaimerIcon={<LockIcon />}
            disclaimerText="We take your privacy seriously. Our institutional grade security ensures ensures your data is confidential."
            leftArrowOnClick={handleBack}
          />
        </ButtonContainer>
      )}
    </PageWrapper>
  );
};

export default ZakatGiftAidPage;
