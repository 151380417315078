import { Investor } from "@ifgengineering/client-invest-sdk";
import { RootStateV2 } from "../rootReducer";

export const neverLoadedInvestorSelector = (state: RootStateV2): boolean =>
  state?.investor?.neverLoaded;

export const isLoadingInvestorSelector = (state: RootStateV2): boolean =>
  state?.investor?.loading;

export const investorSelector = (
  state: RootStateV2
): Partial<Investor> | undefined => state?.investor.data;

export const investorAddressSelector = (
  state: RootStateV2
): Investor["address"] => state?.investor?.data?.address;
