import { DropDownValue } from "@legacyComponents/DropDown/DropDown.component";
import { ZakatGiftAid } from "@Zakat/store/reducers/reducers";
import * as Yup from "yup";

export const initialData: ZakatGiftAid = {
  title: "",
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  postCode: "",
};

export const titles: Record<string, string> = {
  ["Mr."]: "Mr.",
  ["Mrs."]: "Mrs.",
  ["Ms."]: "Ms.",
  ["Miss."]: "Miss.",
  ["Dr."]: "Dr.",
  ["Prof."]: "Prof.",
};

export const titleOptions = Object.keys(titles).map<DropDownValue>(
  (title: string) => ({
    value: title,
    label: titles[title],
  })
);

export const ZakatGiftAidSchema = Yup.object().shape({
  title: Yup.object().required("title is required"),
  firstName: Yup.string().required("first name is required"),
  lastName: Yup.string().required("last name is required"),
  addressLine1: Yup.string().required("address is required"),
  city: Yup.string().required("city is required"),
  postCode: Yup.string().required("address is required"),
});

export const ZakatGiftAidSchemaV2 = Yup.object().shape({
  firstName: Yup.string().required("first name is required"),
  lastName: Yup.string().required("last name is required"),
  addressLine1: Yup.string().required("address line 1 is required"),
  city: Yup.string().required("city is required"),
  postcode: Yup.string().required("post code is required"),
});
